import axios from 'axios'
import { CONSTANTS } from 'src/components/Constants';
axios.defaults.withCredentials = true;

export function handleDiagramUrl(setSelectedMode) {
  const queryParams = new URLSearchParams(window.location.search)
  const queryParamsSize = queryParams.size
  let isValidState = false
  for (var key in CONSTANTS) {
    if (CONSTANTS[key] === queryParams.get('state')) {
      isValidState = true
      break
    }
  }
  if (isValidState && queryParamsSize === 1 && queryParams.get('state')) {
    localStorage.setItem('selectedMode', queryParams.get('state'))
    if (setSelectedMode)
      setSelectedMode(queryParams.get('state'))
    return true;
  }
  return false;
}

export const handleIconNameChange = (index, newName, iconNames, setIconNames) => {
  const newIconNames = [...iconNames];
  newIconNames[index] = newName;
  setIconNames(newIconNames);
};

export async function uploadAssets(token, payload, setUploading, callback) {

  if ((payload.files.length > 0 && payload.iconNames.length === payload.files.length) || (payload.url.length > 0)) {

    alert(JSON.stringify(payload))

    setUploading(true)
    try {
      var userId = sessionStorage.getItem("userFetchedId");
      var email = sessionStorage.getItem("userFetchedEmail");

      const emptyArray = [];

      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('email', email);
      
      // selected file
      if (payload.files.length) {
        payload.files.forEach((file) => formData.append('files', file));
        payload.iconNames.forEach((name) => {
          const nameWithUnderscores = name.replace(/ /g, "_");
          formData.append('iconNamesList', nameWithUnderscores);
        });
      } else {
        formData.append('files', JSON.stringify(emptyArray));
        formData.append('iconNamesList', JSON.stringify(emptyArray));
      }

      // urls
      if (payload.url.length) {
        payload.url.forEach((url) => formData.append('imageUrlList', url));
        payload.urlIconNames.forEach((name) => {
          const nameWithUnderscores = name.replace(/ /g, "_");
          formData.append('imageUrlNames', nameWithUnderscores);
        });
      } else {
        formData.append('imageUrlList', JSON.stringify(emptyArray));
        formData.append('imageUrlNames', JSON.stringify(emptyArray));
      }

      axios
        .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/userasset/upload`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          callback(response);
        })
        .catch((err) => {
          setUploading(false)
          console.log("Error occured " + err.msg);
        })
    } catch (error) {
      setUploading(true)
      console.error('Error uploading file:', error.message);
    }
  } else {
    alert("Please add image(s) and provide a name for each")
    setUploading(false)
    console.log('No Files to Upload');
  }
}

export async function saveCodeFn(value, event, token, data, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar, callback) {

  var apiPath = "/api/diagram/diagram/create";
  if (event && event === "UPDATE") apiPath = "/api/diagram/diagram/update";
  axios.post(`${process.env.REACT_APP_SIDEBAR_API_URL + apiPath}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      console.log('Error:', error);
      setSnackbarMessage("Internal Server error");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
    });
}

export async function getTeamListFn(token, setTeamList, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar,) {
  var userId = sessionStorage.getItem("userFetchedId");
  if (userId != null) {
    userId = userId.replace("|", "%7C");
  }
  var apiPath = "/api/diagram/team/get?userId=" + userId;
  axios.get(`${process.env.REACT_APP_SIDEBAR_API_URL + apiPath}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      if (response.data.result) {
        setTeamList(response.data.result)
      }
    })
    .catch(error => {
      console.log('Error:', error);
      setSnackbarMessage("Internal Server error");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
    });
}

export function resendEmailFn(setVerifyEmail) {
  var userId = sessionStorage.getItem("VERIFY_EMAIL_USERID");
  var data = {
    provider: userId.split("|")[0],
    userId: userId.split("|")[1]
  };
  axios
    .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/auth0/email/verify/resend`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      setVerifyEmail(false);
    })
    .catch((err) => {
      console.log("Error occured " + err.msg);
    })
}

export async function fetchUserFn(token, user, callback) {
  if (token && user) {
    var userId = user.sub;
    userId = userId.replace("|", "%7C");
    axios
      .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/get/` + userId, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((response) => {
        const result = response.data.result;
        if (result && result.length > 0) {
          sessionStorage.setItem("userFetched", JSON.stringify(result[0]));
          sessionStorage.setItem("userFetchedId", result[0].userId);
          sessionStorage.setItem("userFetchedEmail", result[0].email);
          callback(true);
        }
      })
      .catch((err) => {
        console.log("Error occured " + err.msg);
        callback(false);
      })
  }
}

export async function getFileListFn(teamId, users, token, setHistory, setCodeByFileName, callback) {

  if (sessionStorage.getItem("userFetchedId") || teamId) {
    var userId = null;
    if (sessionStorage.getItem("userFetchedId")) {
      userId = sessionStorage.getItem("userFetchedId");
      userId = userId.replace("|", "%7C");
    }
    var path = "/api/diagram/diagram/get?userId=" + userId;
    if (teamId) {
      path = "/api/diagram/diagram/get?teamId=" + teamId + "&users=" + users
    }
    axios
      .get(`${process.env.REACT_APP_SIDEBAR_API_URL}` + path, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((response) => {
        const result = response.data.result;

        if (teamId) {
          callback(result);
        } else {
          var codeByFileName = {};
          var filenamesList = [];
          var uniquefilenamesList = [];
          for (var i = 0; i < result.length; i++) {
            if (!result[i].fileName) continue;
            codeByFileName[result[i].fileName] = result[i];
            filenamesList.push(result[i].fileName);
            uniquefilenamesList.push(result[i].fileName);
          }
          sessionStorage.setItem('uniquefilenamesList', JSON.stringify(uniquefilenamesList));
          sessionStorage.setItem('filenamesList', JSON.stringify(filenamesList));
          localStorage.setItem("selectedFileCode", JSON.stringify(codeByFileName));
          setHistory(filenamesList);
          setCodeByFileName(codeByFileName);
        }
      })
      .catch((err) => {
        console.log("Error occured " + err.msg);
      })
  }
}

export async function showPaymentConfirmationFn(token, setShowPaymentConfirm) {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get("session_id") && sessionStorage.getItem("PAYMENT_API_CALLED") !== queryParams.get("session_id")) {
    axios
      .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/stripe/session/get?sessionId=` + queryParams.get("session_id"), {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((response) => {
        const result = response.data.result;
        if (result && result.status === "paid") {
          var payload = JSON.parse(result.payload);
          var createdDate = new Date(payload.created * 1000);
          if (createdDate.getDate() === new Date().getDate())
            setShowPaymentConfirm(true);

          sessionStorage.setItem("PAYMENT_API_CALLED", queryParams.get("session_id"));
        }
      })
      .catch((err) => {
        console.log("Error occured " + err.msg);
      })
  }
}

export function handleDeleteFn(filename, token, setHistory,  setCodeByFileName, callback) {
  var id = null;
  if (filename && localStorage.getItem("selectedFileCode")) {
    var fileListByName = JSON.parse(localStorage.getItem("selectedFileCode"));
    id = fileListByName[filename].id;
  }

  if (id) {
    axios
      .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/diagram/delete`, { id: id, userId: sessionStorage.getItem('userFetchedId') }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then((response) => {
        getFileListFn(null, null, token, setHistory,  setCodeByFileName, function(){})
        callback(true);
      })
      .catch((err) => {
        console.log("Error occured " + err.msg);
        callback(false);
      })
  }
}

export async function genarateShareDiagramUrl(token, data, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar, callback) {

  var lastGeneratedDiagramByModes={[CONSTANTS.ARCHITECTURE_DIAGRAM]: "LASTGENERATEDIMAGE", [CONSTANTS.ARCHGO]: "ARCHGO_IMG", [CONSTANTS.FLOW_CHART]: "FLOWCHART_IMAGE", [CONSTANTS.CLASS_DIAGRAM]: "CLASS_DIAGRAM_IMAGE", [CONSTANTS.D2]: "D2_IMG", [CONSTANTS.PROTOBUF]: "PROTOBUF_IMG", [CONSTANTS.AVROSCHEMA]: "AVROSCHEMA_IMG", [CONSTANTS.DOCKERCOMPOSE]: "DOCKER_COMPOSE_IMG", [CONSTANTS.TERRAFORM]: "TERRAFORM_IMG", [CONSTANTS.BRAINSTORM]: "BRAINSTORM_IMG", [CONSTANTS.MINDMAP]: "MINDMAP_IMG", [CONSTANTS.MATHEQUATION]: "MATH_EQ_SVG_BASE64", [CONSTANTS.PLAYBOOK_GRAPHER]: "PlaybookGrapher_IMG", [CONSTANTS.INVENTORY_GRAPHER]: "InventoryGrapher_IMG"}
  if(data.mode===CONSTANTS.ARCHITECTURE_DIAGRAM || data.mode===CONSTANTS.FLOW_CHART || data.mode===CONSTANTS.CLASS_DIAGRAM || data.mode===CONSTANTS.DOCKERCOMPOSE){
    data.base64Image= localStorage.getItem(lastGeneratedDiagramByModes[data.mode])
  }else if(data.mode===CONSTANTS.ARCHGO || data.mode===CONSTANTS.AVROSCHEMA || data.mode===CONSTANTS.TERRAFORM || data.mode===CONSTANTS.INVENTORY_GRAPHER){
    data.base64Image= localStorage.getItem(lastGeneratedDiagramByModes[data.mode])
    data.base64Image= `data:image/png;base64,${data.base64Image}`
  }else if(data.mode===CONSTANTS.D2 || data.mode===CONSTANTS.PROTOBUF || data.mode===CONSTANTS.BRAINSTORM || data.mode===CONSTANTS.MINDMAP || data.mode === CONSTANTS.MATHEQUATION || data.mode===CONSTANTS.PLAYBOOK_GRAPHER){
    data.base64Image= localStorage.getItem(lastGeneratedDiagramByModes[data.mode])
    data.base64Image= `data:image/svg+xml;base64,${data.base64Image}`
  }

  axios.post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/share-diagram/create`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      if (response.data.result) {
        callback(response.data.result)
      } else {
        setSnackbarMessage("Internal Server error");
        setSnackbarSeverity("warning");
        setOpenSnackbar(true);
      }
    })
    .catch(error => {
      console.log('Error:', error);
      setSnackbarMessage("Internal Server error");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
      callback(null);
    });

}

export async function getSharedDiagramCode(token, appendCode, setImageSrc, monacoRef, shareID, imageUrl, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar, callback) {

  var apiPath = "/api/diagram/share-diagram/get?shareId=" + shareID;
  axios.get(`${process.env.REACT_APP_SIDEBAR_API_URL + apiPath}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      if (response.data.result) {
        if (monacoRef.current) {
          monacoRef.current.executeEdits('', [
            { range: monacoRef.current.getModel().getFullModelRange(), text: '' },
          ])
        }
        setImageSrc(imageUrl)
        appendCode(response.data.result[0].code);
        callback(response.data.result)
      } else {
        setSnackbarMessage("Internal Server error");
        setSnackbarSeverity("warning");
        setOpenSnackbar(true);
      }
    })
    .catch(error => {
      console.log('Error:', error);
      setSnackbarMessage("Internal Server error");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
      callback(null);
    });

}

export function isSvgUrlEncoded(svg) {
  return svg.startsWith("data:image/svg+xml,%3C");
}


export const generateBrainstormDiagram = async (brainStromJsonValue, isDownload) => {
  
  let data = {
    code: brainStromJsonValue,
  }

  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/brain-storm/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]


    } else {
      console.log('Error:', response.data.result)
      return null
    }

  } catch (error) {
    console.error('Error generating brainstorm diagram:', error)
    return null
  }
}

export const generatePlaybookGrapherDiagram = async (playbookGrapherJsonValue, isDownload) => {
  
  let data = {
    code: playbookGrapherJsonValue,
  }

  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/ansible-grapher/playbook/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]


    } else {
      console.log('Error:', response.data.result)
      return null
    }

  } catch (error) {
    console.error('Error generating playbookGrapher diagram:', error)
    return null
  }
}

export const generateInventoryGrapherDiagram = async (inventoryGrapherJsonValue, isDownload) => {
  
  let data = {
    code: inventoryGrapherJsonValue,
    groupBy: "all"
  }

  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/ansible-grapher/inventory/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]


    } else {
      console.log('Error:', response.data.result)
      return null
    }

  } catch (error) {
    console.error('Error generating inventoryGrapher diagram:', error)
    return null
  }
}

export const generateProtobufDiagram = async (protobufJsonValue, isDownload) => {
  let data = {
    code: 'syntax = "proto3";\n' + protobufJsonValue,
  }

  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/protobuf/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]
    } else {
      console.log('Error:', response.data.result)
      return null
    }
  } catch (error) {
    console.error('Error generating protobuf diagram:', error)
    return null
  }
}

export const generateTerraformDiagram = async (terraformJsonValue, isDownload) => {
  let data = {
    code:  terraformJsonValue,
  }
  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/terraform/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]
    } else {
      console.log('Error:', response.data.result)
      return null
    }
  } catch (error) {
    console.error('Error generating protobuf diagram:', error)
    return null
  }
}

export const generateArchGoDiagram = async (archGoJsonValue, isDownload) => {

  archGoJsonValue.code = archGoJsonValue.code.replace(/ {4}/g, "\t");
  if(!archGoJsonValue.diagramName || archGoJsonValue.diagramName.trim().length === 0){
    archGoJsonValue.diagramName= "My Diagram"
  } 
  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/go-arch/generate` + downloadQuery,
      archGoJsonValue,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]
    } else {
      console.log('Error:', response.data.result)
      return null
    }
  } catch (error) {
    console.error('Error generating Arch diagram:', error)
    return null
  }
}

export const generateAvroSchemaDiagram = async (avroJsonValue, isDownload, file) => {
  let data = {
    code: avroJsonValue,
    isFile: 0,
    avscFile: null
  }

  if(file){
    data.isFile= 1;
    data.avscFile= file;
  }

  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/avro/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      let res = data.split(',')
      return res[1]
    } else {
      console.log('Error:', response.data.result)
      return null
    }
  } catch (error) {
    console.error('Error generating avroSchema diagram:', error)
    return null
  }
}

export const generateDockerComposeDiagram = async (dockerComposeJsonValue, isDownload) => {
  let data = {
    code: "version: '3'\n" + dockerComposeJsonValue,
  }

  try {
    let downloadQuery = "?isDownload=false"
    if (isDownload) {
      downloadQuery = "?isDownload=true"
    }
    const response = await axios.post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/c2d/docker-compose/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (
      response &&
      response.data.result &&
      response.data.result.length > 0 &&
      response.data.result[0] === '200'
    ) {
      let data = response.data.result[1]
      return data
    } else {
      console.log('Error:', response.data.result)
      return null
    }
  } catch (error) {
    console.error('Error generating dockerCompose diagram:', error)
    return null
  }
}

export const generateArchitectureDiagramFn = async (editorValue, isDownload, setImageHeight, setImageWidth, setImageSrc, 
  setPythonError, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar, setOpenSnackbarDiagram,
  diagramName, orientation, setLoading, callback) => {

  var code = "DIAGRAM_NAME='" + diagramName + "'\nORIENTATION='" + orientation + "'\n" + editorValue

  var existingIconCode = JSON.parse(sessionStorage.getItem('ICONCODE')) || {}
    for (var key in existingIconCode) {
      var iconKey = key.split('--SPLIT--')
      if (code.includes(iconKey[0] + '_ICON')) {
        const regex = new RegExp(iconKey[0] + '_ICON', 'gi')
        code = code.replace(regex, iconKey[1])
        code = existingIconCode[key] + '\n' + code
      }
    }

  const data = {
    code: code,
    deleteFilesList: [],
    ipAddress: sessionStorage.getItem('ipAddress'),
    userAgent: sessionStorage.getItem('userAgent'),
  }

  let downloadQuery = '?isDownload=false'
  if (isDownload) {
    downloadQuery = '?isDownload=true'
  }
  axios
    .post(
      `${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/diagram/generate` + downloadQuery,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      callback(response)
      setOpenSnackbarDiagram(false)
      if(isDownload) return
      if (response && response.data.result.diagram) {
        setImageWidth('100%')
        setImageHeight('80%')
        setImageSrc(response.data.result.diagram)
        setLoading(false)
        sessionStorage.setItem('LAST_GENERATED_DIAGRAM', response.data.result.diagram)
        localStorage.setItem('LASTGENERATEDIMAGE', response.data.result.diagram)
        localStorage.setItem('ORIENTATIONS', orientation)
        localStorage.setItem('EDITORSTATE', editorValue)
      } else if (response && response.data.result.diagramError) {
        setPythonError(response.data.result.diagramError)
        setSnackbarMessage(response.data.result.diagramError)
        setSnackbarSeverity('error')
        setOpenSnackbar(true)
        setLoading(false)
        setImageSrc('')
      } else {
        setSnackbarMessage('Internal Server error')
        setSnackbarSeverity('error')
        setOpenSnackbar(true)
        setLoading(false)
        setImageSrc('')
      }
    })
    .catch((error) => {
      console.log('Error:', error)
      callback(null)
      setOpenSnackbarDiagram(false)
      setSnackbarMessage('Compilation failed. Invalid input format')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
    })
}