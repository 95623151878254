import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PanZoom } from 'react-easy-panzoom'
import axios from 'axios'
import { generateDockerComposeDiagram } from 'src/views/dashboard/DashboardFunctions'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const DockerCompose = ({
  panZoomRef,
  setOpenSnackbar,
  setOpenSnackbarDiagram,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let editorValue = useSelector((state) => state.dockerComposeEditor.dockerComposeEditorValue)
  const [dockerComposeImage, setDockerComposeImage] = useState('')
  const [loading, setLoading] = useState(true)
  const [previousCode, setPreviousCode] = useState('')
  const [initial, setInitial] = useState(true)

  axios.defaults.withCredentials = true

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {
    const generateDockerComposeImage = async () => {
      if(!editorValue.trim()){
        setDockerComposeImage('')
        setPreviousCode('')
        return
      }
      try {
        if (previousCode.trim() === editorValue.trim()) return
        setPreviousCode(editorValue)
        setOpenSnackbarDiagram(true)

        const outputImage = await generateDockerComposeDiagram(editorValue, false)
        if (
          (outputImage != null || !outputImage.includes('failed to compile')) &&
          !outputImage.startsWith('data:image/png;base64,Traceback')
        ) {
          setDockerComposeImage(outputImage)
          localStorage.setItem('DOCKER_COMPOSE_IMG', outputImage)
          sessionStorage.setItem('LAST_GENERATED_DIAGRAM', outputImage)
          setLoading(false)
          setOpenSnackbarDiagram(false)
        } else {
          setLoading(false)
          setOpenSnackbar(true)
          setSnackbarMessage('Compilation failed. Invalid input format')
          setSnackbarSeverity('error')
          setOpenSnackbarDiagram(false)
        }
      } catch (error) {
        console.error('Error generating dockerCompose diagram:', error)
        setLoading(false)
        setOpenSnackbar(true)
        setSnackbarMessage('Compilation failed. Invalid input format')
        setSnackbarSeverity('error')
      }
    }
    if (initial) {
      generateDockerComposeImage()
      setInitial(false)
    }

    if (!initial) {
      const intervalId = setInterval(generateDockerComposeImage, 4000)
      return () => clearInterval(intervalId)
    }
    localStorage.setItem('selectedMode', CONSTANTS.DOCKERCOMPOSE)
  }, [editorValue, previousCode, initial])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      {loading && (
        <div className="alignMiddle">
          <CircularProgress />
          Executing...
        </div>
      )}
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          <div className="alignMiddle">
            {!loading && (dockerComposeImage?(
              <img src={dockerComposeImage} alt="DockerCompose Diagram" className="image-style" />
            ) : (
              <div style={{ color: 'gray', fontSize: '24px' }}></div>
            ))}
          </div>
        </PanZoom>
      </MapContainer>
    </div>
  )
}
export default DockerCompose
