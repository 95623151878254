import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import dayjs from 'dayjs'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { CForm, CFormInput, CAlert } from '@coreui/react'
import '../../scss/_custom.scss'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'

import PortraitIcon from '@mui/icons-material/Portrait'
import StarIcon from '@mui/icons-material/Star'
import WidgetsIcon from '@mui/icons-material/Widgets'
import TvIcon from '@mui/icons-material/Tv'
import { Button } from '@mui/material'

export const ProfileComponent = (props) => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [showDanger, setDanger] = useState(false)
  const { user, getAccessTokenSilently, isAuthenticated, logout, loginWithRedirect } = useAuth0()
  const [value, setValue] = useState(dayjs(new Date()))

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [linkedin, setLinkedin] = useState('')
  const [twitter, setTwitter] = useState('')
  const [additional, setAdditional] = useState('')
  const [address, setAddress] = useState('')
  const [showSpinner, setShowSpinner] = useState(true)

  axios.defaults.withCredentials = true

  const update = async (event) => {
    event.stopPropagation()
    event.preventDefault()

    const token = await getAccessTokenSilently()
    const data = {
      userId: sessionStorage.getItem('userFetchedId'),
      name: name,
      email: email,
    
      linkedinProfile: linkedin,
      twitterProfile: twitter,
      additionalProfile: additional,
      address: address,
    }

    axios
      .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/update`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        sessionStorage.setItem('userFetched', JSON.stringify(data))
        sessionStorage.setItem('userFetchedId', data.userId)
        sessionStorage.setItem('userFetchedEmail', data.email)
        
        sessionStorage.setItem('userFetchedLinkedin', data.linkedinProfile)
        sessionStorage.setItem('userFetchedTwitter', data.twitterProfile)
        sessionStorage.setItem('userFetchedAdditional', data.additionalProfile)
        sessionStorage.setItem('userFetchedAddress', data.address)
        setVisible(true)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((error) => {
        console.log('Error:', error)
        setDanger(true)
      })
  }

  useEffect(() => {
    ;(async () => {
      const token = await getAccessTokenSilently()
      var userId = sessionStorage.getItem('userFetchedId')
      if (userId != null) {
        userId = userId.replace('|', '%7C')
      }
      axios
        .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/get/` + userId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const result = response.data.result
          setShowSpinner(false)
          setName(result[0].name)
          setEmail(result[0].email)
        
          setLinkedin(result[0].linkedinProfile)
          setTwitter(result[0].twitterProfile)
          setAdditional(result[0].additionalProfile)
          setAddress(result[0].address)
        })
        .catch((err) => {
          setShowSpinner(false)
          console.log('Error occured ' + err.msg)
        })
    })()
  }, [])

  function handleUpgrade() {
    if (isAuthenticated) {
      setVisible(!visible)
      fetchUser()
    } else {
      sessionStorage.setItem('NAVIGATE_TO', '/pricing')
      manageLogin()
    }
  }

  function manageLogin() {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get('error') && queryParams.get('error_description')) {
      logout()
    }
    loginWithRedirect()
  }

  async function fetchUser() {
    if (sessionStorage.getItem('userFetchedEmail')) {
      navigate('/pricing')
      return
    }
    const token = await getAccessTokenSilently()
    if (token && user) {
      var userId = user.sub
      userId = userId.replace('|', '%7C')
      axios
        .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/get/` + userId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const result = response.data.result
          if (result && result.length > 0) {
            sessionStorage.setItem('userFetched', JSON.stringify(result[0]))
            sessionStorage.setItem('userFetchedId', result[0].userId)
            sessionStorage.setItem('userFetchedEmail', result[0].email)
            navigate('/pricing')
          }
        })
        .catch((err) => {
          console.log('Error occured ' + err.msg)
        })
    }
  }

  return (
    <div className="profile">
      <CAlert color="primary" dismissible visible={visible} onClose={() => setVisible(false)}>
        User Updated Suceessfully
      </CAlert>
      <CAlert color="danger" dismissible visible={showDanger} onClose={() => setDanger(false)}>
        Internal Server Error
      </CAlert>
      <br /> <br /> <br />
      <div className="white-section">
        <Row className="align-items-center profile-header mb-5 text-center text-md-left">
          <Row className="profile_picture">
            <img src={user.picture} alt="Profile" className="rounded-circle" />
          </Row>
          <div className="data">
            <Col md>
              <h2 className="userName">{name}</h2>

              <p  className="userInfo">
                <span>
                  <MailOutlineIcon />
                </span>
                <b style={{ marginLeft: '5px' }}>{user.email}</b>
              </p>


              <p  className="userInfo">
                <span>
                  <LinkedInIcon />
                </span> 
                <b className="userLinkedIn" style={{ marginLeft: '5px' }}>{linkedin}</b>
              </p>

              <p  className="userInfo">
                <span>
                  <TwitterIcon />
                </span>
                <b className="userTwitter" style={{ marginLeft: '5px' }}>{twitter}</b>
              </p>
            </Col>
          </div>
        </Row>
        <div className="switch_data">
          <p onClick={() => navigate('/profile')} className="switch_icons">
            <span>
              <PortraitIcon />
              About Me
            </span>
          </p>
          <p className="switch_icons">
            <span>
              <StarIcon />
            </span>
            Teams
          </p>
          <p className="switch_icons">
            <span>
              <WidgetsIcon />
            </span>
            File
          </p>
          <p className="switch_icons" onClick={() => handleUpgrade()}>
            <span>
              <TvIcon />
            </span>
            Premium
          </p>
        </div>
      </div>
      <div className="about">
        <h2 className="about_me">
          About Me
          <span className="icons">
            <LinkedInIcon
              onClick={() =>
                (window.location.href = 'https://www.linkedin.com/company/code2diagram?trk=public_post_feed-actor-name')
              }
            />

            <MailOutlineIcon />
            <TwitterIcon />
            {/* <FacebookIcon /> */}
          </span>
        </h2>

        <div className="gray-section">
          <CForm onSubmit={update}>
            <CFormInput
              type="text"
              id="floatingName"
              floatingLabel="Name"
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              className="custom-form-input"
            />
            <br />

            <CFormInput
              disabled
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              id="floatingInput"
              floatingLabel="Email address"
              placeholder="name@example.com"
              className="custom-form-input"
            />
            <br />

            <CFormInput
              type="text"
              id="linkedin"
              floatingLabel="LinkedIn Profile"
              placeholder="LinkedIn Profile"
              value={linkedin}
              onChange={(e) => {
                setLinkedin(e.target.value)
              }}
              className="custom-form-input"
            />
            <br />

            <CFormInput
              type="text"
              id="twitter"
              floatingLabel="Twitter Profile"
              placeholder="Twitter Profile"
              value={twitter}
              onChange={(e) => {
                setTwitter(e.target.value)
              }}
              className="custom-form-input"
            />
            <br />

            <CFormInput
              type="text"
              id="additional_profile"
              floatingLabel="Additional Profile"
              placeholder="Additional Profile"
              value={additional}
              onChange={(e) => {
                setAdditional(e.target.value)
              }}
              className="custom-form-input"
            />
            <br />

            <CFormInput
              type="text"
              id="address"
              floatingLabel="Address"
              placeholder="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              className="custom-form-input"
            />
<br/>
            <div className="d-grid gap-2">
              <Button style={{ color: '#fff', backgroundColor:'#EF6501', borderBlock: ' #EF6501', width: '90px' }}className="update_button" type="submit">
                Update
              </Button>
            </div>
          </CForm>
        </div>
      </div>
    </div>
  )
}

export default withAuthenticationRequired(ProfileComponent, {})
