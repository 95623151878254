import { Box, Button, Modal, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import { modalStyle } from 'src/utils/history';

const SaveModal = ({
  visibleSave,
  setVisibleSave,
  setFilename,
  saveCodeApi,
  filename,
  monacoRef,  
}) => {
  const [saveToDisk, setSaveToDisk] = useState(false); 
 
  const saveToFileSystem = (filename, content) => {
    const blob = new Blob([content], { type: 'text/plain' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
  };

  const handleSave = () => {
    if (!monacoRef.current) {
      console.error('Monaco editor not ready!');
      return; 
    }

    const content = monacoRef.current.getValue();
    
    
    if (saveToDisk) {
      saveToFileSystem(filename, content); 
    }
    
    
    saveCodeApi(filename, content); 

    setVisibleSave(false); 
  };

  return (
    <Modal open={visibleSave} onClose={() => setVisibleSave(false)}>
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Save File
        </Typography>
        <hr />
        <div>
          <Typography id="modal-modal-description" sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
            <span className='saveheading'>Please enter a new name for the file</span>
          </Typography>
          <TextField
            size="small"
            hiddenLabel
            id="filename"
            placeholder="File Name"
            autoComplete="off"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            sx={{ width: '100%' }}
          />
          <Typography sx={{ mt: 2 }}>
            Check this box to save and download the file to disk:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={saveToDisk}  
                onChange={(e) => setSaveToDisk(e.target.checked)} 
              />
            }
            label="Save to disk"
          />
        </div>
        <footer
          style={{
            display: 'flex',
            gap: '5px',
            justifyContent: 'flex-end',
            margin: '15px',
            width: '94%',
          }}
        >
          <Button size="small" className="cancel-button" onClick={() => setVisibleSave(false)}>
            Cancel
          </Button>
          <Button
            className="icon-button"
            onClick={handleSave}
            disabled={!filename || !monacoRef.current}  
          >
            Save
          </Button>
        </footer>
      </Box>
    </Modal>
  );
};

export default SaveModal;
